import { useEffect, useState } from "react";
import { IcSharpSync } from "./utils/icons";
import { IcRoundFavorite } from "./utils/icons";
import { IcRoundFavoriteBorder } from "./utils/icons";
import Container from "./components/container";

export default function Random() {
  const [randomGif, setRandomGif] = useState(null);
  const [gifsFav, setGifsFav] = useState(
    localStorage.getItem("gifsFav") ? localStorage.getItem("gifsFav") : "[]"
  );

  async function fetchGiphy() {
    const response = await fetch(
      "https://api.giphy.com/v1/gifs/random?api_key=8kV1qZcqNe0H14DkEFjlZbZpNIPrPcGS&tag=&rating=g"
    );
    const data = await response.json();
    setRandomGif(data.data.images.original.url);
  }

  useEffect(() => {
    if (!randomGif) fetchGiphy();
  }, []);

  useEffect(() => {
    if (gifsFav==="[]") {
      setGifsFav(localStorage.getItem("gifsFav"));
    }
    localStorage.setItem("gifsFav", gifsFav);
  }, [gifsFav]);

  return (
    <Container page={2}>
      <h1>Random Gif</h1>
      <IcSharpSync className="reload" onClick={() => fetchGiphy()} />
      <div style={{ position: "relative" }}>
        <img className="gif" src={randomGif} alt={randomGif}/>
        {JSON.parse(gifsFav) &&
        JSON.parse(gifsFav).includes(randomGif) ? (
          <IcRoundFavorite
            className="favorite"
            onClick={() => {
              const gifsFavNew = JSON.parse(gifsFav);
              setGifsFav(
                JSON.stringify(
                  gifsFavNew.filter((row) => row !== randomGif)
                )
              );
            }}
          />
        ) : (
          <IcRoundFavoriteBorder
            className="favorite"
            onClick={() => {
              const gifsFavNew = JSON.parse(gifsFav) || [];
              gifsFavNew.push(randomGif);
              setGifsFav(JSON.stringify(gifsFavNew));
            }}
          />
        )}
      </div>
    </Container>
  );
}
