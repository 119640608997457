import { useEffect, useState } from "react";
import { IcSharpClose } from "./utils/icons";
import Container from "./components/container";

export default function Favorites() {
  const [gifsFav, setGifsFav] = useState(
    localStorage.getItem("gifsFav")
      ? JSON.parse(localStorage.getItem("gifsFav"))
      : []
  );

  useEffect(() => {
      console.log(gifsFav);
  }, [gifsFav]);

  return (
    <Container page={1}>
      <h1>Favorites</h1>
      <div className="gifs_container">
        {gifsFav &&
          gifsFav.map((gif) => (
            <div className="gif_container" key={gif}>
              <img className="gif" src={gif} />
              <IcSharpClose className="favorite" onClick={()=>{
                const gifsFavNew = gifsFav;
                setGifsFav(gifsFavNew.filter((row) => row!==gif));
                localStorage.setItem("gifsFav", JSON.stringify(gifsFavNew.filter((row) => row!==gif)));
              }}/>
            </div>
          ))}
      </div>
    </Container>
  );
}
