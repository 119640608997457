export default function Footer() {
    return (
      <footer>
        <div className="footer_container">
            <p>Guillaume Marcelino</p>
            <p>Master Dev - Groupe 1</p>
            <a target="blank" href="https://github.com/GuillaumeMarcel">github.com/GuillaumeMarcel</a>
            <p>Efrei Paris</p>
        </div>
      </footer>
    );
  };
  