import { useEffect, useState } from "react";
import Container from "./components/container";
import "./style/home.css";
import { IcRoundFavorite } from "./utils/icons";
import { IcRoundFavoriteBorder } from "./utils/icons";

function Accueil() {
  const [gifs, setGifs] = useState(null);
  const [search, setSearch] = useState("cats");
  const [gifsFav, setGifsFav] = useState("[]");

  async function fetchGiphy() {
    const response = await fetch(
      `https://api.giphy.com/v1/gifs/search?api_key=8kV1qZcqNe0H14DkEFjlZbZpNIPrPcGS&q=${search}&limit=25&offset=0&rating=g&lang=fr`
    );
    const data = await response.json();
    setGifs(data.data);
  }

  useEffect(() => {
    if (gifs === null) fetchGiphy();
  }, [gifs]);

  useEffect(() => {
    if (gifsFav === "[]") {
      setGifsFav(localStorage.getItem("gifsFav"));
    }
    localStorage.setItem("gifsFav", gifsFav);
  }, [gifsFav]);

  return (
    <Container page={0}>
      <div className="search">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <input type="submit" value="Rechercher" onClick={() => fetchGiphy()} />
      </div>
      <div className="gifs_container">
        {gifs && gifs.length > 0 ? (
          gifs.map((gif) => (
            <div className="gif_container" key={gif.id}>
              <img className="gif" src={gif.images.original.url} alt={gif.images.original.url}/>
              {JSON.parse(gifsFav) &&
              JSON.parse(gifsFav).includes(gif.images.original.url) ? (
                <IcRoundFavorite
                  className="favorite"
                  onClick={() => {
                    const gifsFavNew = JSON.parse(gifsFav);
                    setGifsFav(
                      JSON.stringify(
                        gifsFavNew.filter(
                          (row) => row !== gif.images.original.url
                        )
                      )
                    );
                  }}
                />
              ) : (
                <IcRoundFavoriteBorder
                  className="favorite"
                  onClick={() => {
                    const gifsFavNew = JSON.parse(gifsFav) || [];
                    gifsFavNew.push(gif.images.original.url);
                    setGifsFav(JSON.stringify(gifsFavNew));
                  }}
                />
              )}
            </div>
          ))
        ) : (
          <h1> Aucun résultat. </h1>
        )}
      </div>
    </Container>
  );
}

export default Accueil;
