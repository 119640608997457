import React, { useState } from "react";

import "./style/global.css";
import "./style/lightmod.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Accueil from "./Accueil";
import Favorites from "./Favorites";
import Random from "./Random";

import { ThemeContext } from "./utils/ThemeContext";

function App() {
  const [theme, setTheme] = useState("dark");
  return (
    <BrowserRouter>
      <ThemeContext.Provider value={{theme, setTheme}}>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/random" element={<Random />} />
        </Routes>
      </ThemeContext.Provider>
    </BrowserRouter>
  );
}

export default App;
