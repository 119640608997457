import Footer from "./footer";
import Header from "./header";
import Main from "./main";
import NavBar from "./nav";
import { ThemeContext } from "../utils/ThemeContext";
import { useContext } from "react";

export default function Container({ page, children }) {
  const {theme} = useContext(ThemeContext);
  return (
    <div className={`body ${theme === "light"&& "light"}`}>
      <Header />
      <NavBar page={page}/>
      <Main>
        {children}
      </Main>
      <Footer />
    </div>
  );
};
