import { Link } from "react-router-dom";
import { ThemeContext } from "../utils/ThemeContext";
import { useContext } from "react";

export default function NavBar({ page }) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="nav">
      <ul className={theme === "light"&& "light"}>
        <Link to="/">
          <li className={`${page === 0 && "selected"}`}>Accueil</li>
        </Link>
        <Link to="/favorites">
          <li className={`${page === 1 && "selected"}`}>Favoris</li>
        </Link>
        <Link to="/random">
          <li className={`${page === 2 && "selected"}`}>Random</li>
        </Link>
      </ul>
    </div>
  );
}
