import { useContext } from "react";
import { OcticonCheckCircle16 } from "../utils/icons";
import { OcticonCheckCircleFill12 } from "../utils/icons";

import { ThemeContext } from "../utils/ThemeContext";

export default function Header() {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <header>
      <h1>Guillaume Marcelino - API Giphy </h1>
      {theme === "dark" ? (
        <p className="theme" onClick={()=>setTheme("light")} ><OcticonCheckCircle16 className="themeicon" /> Dark Theme</p>
      ) : (
        <p className="theme" onClick={()=>{setTheme("dark")}} ><OcticonCheckCircleFill12 className="themeicon"/> Light Theme</p>
      )}
    </header>
  );
}
